<template>
  <amf-page id="amf-admin"
            :hasBreadcrumbs="true"
            :hasSidebar="true"
            :class="'admin-page'">

    <template #sidebar>
      <md-card>
        <md-card-content>
          <md-list>
<!--            <md-list-item :to="{ name: 'adminDashboard'}">Dashboard</md-list-item>-->
            <md-list-item to="/admin/users">Users</md-list-item>
            <md-list-item to="/admin/sites">Sites</md-list-item>
            <md-list-item to="/admin/unlistedSites">Unlisted Sites</md-list-item>
            <md-list-item to="/admin/uploadedWebsites">Uploaded Websites</md-list-item>
            <md-list-item to="/admin/favPages">FavPages</md-list-item>
            <md-list-item :to="{ name: 'mostPopularSites' }">Most Popular Sites</md-list-item>
            <md-list-item to="/admin/settings">Settings</md-list-item>
            <md-list-item :to="{ name: 'adminEditorTokens' }">Editor Tokens</md-list-item>
            <md-list-item :to="{ name: 'email' }">Email</md-list-item>
            <md-list-item :to="{ name: 'banner-settings' }">Banner Settings</md-list-item>
          </md-list>
        </md-card-content>
      </md-card>
    </template>

    <template #content>
      <router-view></router-view>
    </template>
  </amf-page>
</template>

<script>
import AmfPage from '@/components/page-templates/AmfPage.vue';

export default {
  name: 'AdminMain',
  components: {
    AmfPage,
  },
};
</script>
