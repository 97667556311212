<template>
  <div :id="id" class="amf-page">
    <div class="md-layout tabs-container">
      <div id="amf-page-sidebar" v-if="hasSidebar" class="md-layout-item md-size-15 md-small-size-100">
        <slot name="sidebar">
          <h2>AMF SIDEBAR</h2>
        </slot>
      </div>

      <div id="amf-page-content" class="md-size-85 xs-size-100 md-layout-item">
        <slot name="content">
          <h2>AMF CONTENT</h2>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import loaderMixin from '@/mixins/loaderMixin';

export default {
  name: 'AmfPage',
  mixins: [
    loaderMixin,
  ],
  props: {
    id: {
      type: String,
      required: true,
    },
    hasBreadcrumbs: {
      type: Boolean,
      default: false,
    },
    hasSidebar: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/layout/amf-page.scss';
</style>
